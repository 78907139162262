// import ResponsiveAppBar from "../components/ResponsiveAppBar"

export default function PageOne(){
    return(
      <div>
        {/* <ResponsiveAppBar></ResponsiveAppBar> */}
        <h1>Page one new</h1>
      </div>
    )
}
