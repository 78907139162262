import React from 'react';
// import {
//   Page,
//   Text,
//   View,
//   Document,
//   StyleSheet,
//   PDFDownloadLink,
// } from '@react-pdf/renderer';
// import { PDFViewer } from '@react-pdf/renderer';
// import ReactPDF from '@react-pdf/renderer';
// import Invoice from '../../components/Invoice';
import {ReimbursementForm}  from '../../components/ReimbursementForm';
// Create styles

export default function ReimbursementGeneration() {
  return (
    <div>
      <ReimbursementForm></ReimbursementForm>
    </div>
  );
}
